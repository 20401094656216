
import React from 'react';
import { css } from '@emotion/react';
import { SEO } from '@/components';

const Error: React.FC = () => (
    <>
        <SEO title="404: Not found" description="404 page" keywords="" url="" />
        <div css={css`height: 100vh; width: 100%; display: flex; justify-content: center; align-items: center;`}>
            <h1>404 | This page could not be found</h1>
        </div>
    </>
);


export default Error;